import React, { ReactElement } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';

const SpaceContainerDynamic = dynamic<{ selectedFolderId?: string }>(
  () => import('../../containers/space/space.container'),
  {
    ssr: false,
  },
);

const TestMakerLayoutDynamic = dynamic(
  () => import('../../containers/test-maker-layout/test-maker.layout'),
  {
    ssr: false,
  },
);

const SpacePage = () => {
  const pageTitle = 'form';

  return (
    <TestMakerLayoutDynamic pageTitle={pageTitle}>
      <SpaceContainerDynamic />
    </TestMakerLayoutDynamic>
  );
};

export const getStaticProps = async (context: any) => {
  const { locale } = context;

  if (locale === undefined) {
    throw new Error('locale is missing');
  }
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};

SpacePage.getLayout = function getLayout(page: ReactElement) {
  return page;
};

export default SpacePage;
